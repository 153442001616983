// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  padding-bottom: 60px;
  overflow-x: hidden;
}
/*Add client Modal*/
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
       -o-transform: translate3d(0%, 0, 0);
          transform: translate3d(0%, 0, 0);
}
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}
.modal.right .modal-body {
  padding: 15px 15px 80px;
}
.modal.right.fade .modal-dialog {
  right: -10%;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
     -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
       -o-transition: opacity 0.3s linear, right 0.3s ease-out;
          transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.show .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}
/*END Bookings Form*/
/*Subscription page*/
/*CC Modal*/
#updateCC .modal-dialog { min-width: 500px !important; }
/*Stripe integration*/
.StripeElement { color:rgba(150,150,150,0.9) !important; background-color:rgba(255,255,255,1); border-radius: 3px; height: 40px; padding: 10px 12px; }
/*.StripeElement--focus { box-shadow: 0 1px 3px 0 #cfd7df; }*/
.card-row { margin-left: 0px; margin-right: 0px;}
.StripeElement--invalid { border-color: #fa755a !important; }
.StripeElement--webkit-autofill { background-color: #fefde5 !important; }
#imgmc, #imgvisa { display: none; }
/*end subscription page*/
/*International Phone Flags*/
.iti__flag {background-image: url("../assets/images/flags.png");}
.iti__country-list { background-color:#37434a !important ; }
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("../assets/images/flags@2x.png");}
}
/*END International Phone Flags*/
/*Background Dashboard*/
body { background-color: #f6f8fa; }
/*Card*/
.card { border-radius: 15px; box-shadow: 0px 10px 31px -19px rgba(0,0,0,0.15); display: none; }
/*Table rows*/
.even { background-color: #fcfcfc; }
.table-completed { background-color: rgba(52, 168, 83, 0.1); }
.table-completed:hover { background-color: rgba(52, 168, 83, 0.2) !important; }
/*Subscription link*/
.cancel-link { font-size: 12px; cursor: pointer; }
/*Setup page*/
.location-list { padding-top:2px; margin-top:2px; list-style: none !important; list-style-type: none !important;}
/*Cancel modal*/
#cancelModal { border-radius: 8px !important; }
/*Networks Icons*/
.logo-homeadvisor::before { content: url('../assets/images/homeadvisor-logo.png'); margin-top: 10px; }
.logo-tripadvisor::before { content: url('../assets/images/tripadvisor-logo.png'); margin-top: 10px; }
/*Review Page*/
.review-bg { background-color: #f7f7f7; height: 100vh;}
#rw_stars { unicode-bidi: bidi-override; direction:rtl; margin-top:10px; }
#rw_stars a i { transition:0.3s all; }
#rw_stars a i:hover { color:#f1c40f !important; }
#rw_star1:hover ~ a i { color:#f1c40f !important; }
#rw_star2:hover ~ a i { color:#f1c40f !important; }
#rw_star3:hover ~ a i { color:#f1c40f !important; }
#rw_star4:hover ~ a i { color:#f1c40f !important; }
#rw_star5:hover ~ a i { color:#f1c40f !important; }
#stars-description { display:flex; flex-wrap:wrap; align-items: center; justify-content: center;  font-style:italic; }
.rw-descr { width: 50%; }
// #rw_star1::after { content:"Bad"; unicode-bidi: bidi-override; direction: ltr !important; position: relative; top:25px; right: -40px; height: 40px;}
// #rw_star5::before { content:"Excellent"; unicode-bidi: bidi-override; direction: ltr !important; position: relative; top: 25px; left: -55px; height: 40px; width: 80px; }
#step2 { display: none; }
#negative-form { display: none; }
.rw-button { width: 350px; height: 70px; font-size: 30px; font-weight: 300; color:#ffffff !important; }
.rw-google { background-color: rgba(251, 188, 6, 0.9); border-color: rgba(251, 188, 6, 1); }
.rw-google:hover,.rw-google:active,.rw-google:visited,.rw-google:focus { background-color: rgba(251, 188, 6, 0.6); border-color: rgba(251, 188, 6, 0.7); color: white;}
.rw-google::before { content:'\F2AD'; display: inline-block; font: normal normal normal 22px/1 "Material Design Icons"; font-size: inherit; text-rendering: auto; margin-right: 15px; }
.rw-yelp { background-color: rgba(241, 85, 108, 0.9); border-color: rgba(241, 85, 108, 1); }
.rw-yelp:hover,.rw-yelp:active,.rw-yelp:visited,.rw-yelp:focus { background-color: rgba(241, 85, 108, 0.6); border-color: rgba(241, 85, 108, 0.7); color: white;}
.rw-yelp::before { content:'\F5C2'; display: inline-block; font: normal normal normal 22px/1 "Material Design Icons"; font-size: inherit; text-rendering: auto; margin-right: 15px; }
.rw-facebook { background-color: rgba(2, 168, 181, 0.9); border-color: rgba(2, 168, 181, 1); }
.rw-facebook:hover,.rw-facebook:active,.rw-facebook:visited,.rw-facebook:focus { background-color: rgba(2, 168, 181, 0.6); border-color: rgba(2, 168, 181, 0.7); color: white;}
.rw-facebook::before { content:'\F20C'; display: inline-block; font: normal normal normal 22px/1 "Material Design Icons"; font-size: inherit; text-rendering: auto; margin-right: 15px; }
.rw-homeadvisor { background-color: rgba(247, 144, 29, 0.9); border-color: rgba(247, 144, 29, 1); }
.rw-homeadvisor:hover,.rw-homeadvisor:active,.rw-homeadvisor:visited,.rw-homeadvisor:focus { background-color: rgba(247, 144, 29, 0.6); border-color: rgba(247, 144, 29, 0.7); color: white;}
.rw-homeadvisor::before { content:''; background-image: url('../assets/images/homeadvisor-logo-white.png'); width: 40px; height: 40px; display: inline-block; margin-right: 15px; top: 20px; margin-bottom: -10px; }
.rw-tripadvisor { background-color: rgba(52, 168, 83, 0.9); border-color: rgba(52, 168, 83, 1); }
.rw-tripadvisor:hover,.rw-tripadvisor:active,.rw-tripadvisor:visited,.rw-tripadvisor:focus { background-color: rgba(52, 168, 83, 0.6); border-color: rgba(52, 168, 83, 0.7); color: white;}
.rw-tripadvisor::before { content:''; background-image: url('../assets/images/tripadvisor-logo-white.png'); width: 40px; height: 40px; display: inline-block; margin-right: 15px; top: 20px; margin-bottom: -10px; }
#txtFeedback { min-height: 200px; }
#sms-text { min-height: 200px; }
.iti { width: 100%;}
.legend { font-size: 16px; font-weight: bold;}
/*Responsive Styles */
@media (max-width: 767.98px) {
  .modal-header { border-bottom: none !important;}
  .company-logo { width: 85%;}
  .display-4 { font-size: 3rem !important;}
  .rw-button { width: 96% !important;}
  #frmFeedback { width: 100%; }
  .content-area { padding-left:0.5rem !important; padding-right: 0.5rem !important; }
  #updateCC .modal-dialog { min-width: 100% !important; }
}